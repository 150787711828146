import { studioDocslogEvent } from "../../services/analyticsService";


//debounce function
const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}


  // report clicks on buttons
  const clickListner = () => {
  const candidateButtonElements = document.getElementsByTagName('button');
  for (let i = 0; i < candidateButtonElements.length; i++) {
    const candidate = candidateButtonElements[i];
    if (candidate !== null) {
      candidate.addEventListener("click", function (e) {
        studioDocslogEvent("DocsButtonPressed", {
          elementId: e.target.id,
          elementText: e.target.innerText,
          elementType: e.target.nodeName,
          curr_path: document.location.pathname,
          href: e.target.href
        })
      })
    }
  }

  const candidateInputElements = document.getElementsByTagName('input');
    const handleInputChange = debounce((e) => {
    studioDocslogEvent("DocsInputChanged", {
      elementId: e.target.id,
      value: e.target.value,
      label: e.target.getAttribute('label'),
      curr_path: document.location.pathname,
      })
}, 500);

  for (let i = 0; i < candidateInputElements.length; i++) {
    const inputCandidate = candidateInputElements[i];
    if (inputCandidate !== null) {
      inputCandidate.addEventListener("change", handleInputChange)
    }
  }
}

  /* Wait for page to load and application to mount */
  window.addEventListener("load", function (e) {
    clickListner();
    studioDocslogEvent("PageEntered", {curr_path: e.target.location.pathname, curr_search: e.target.location.search, prev_path: document.referrer})
  })


 // on path changed
  const onPathChangedListner = () => {
    let prevPath = location.pathname;
    let isListnerAdded = false;
    document.body.addEventListener('click', (e) => {
      requestAnimationFrame(() => {
        if (prevPath !== location.pathname) {
          clickListner();
          studioDocslogEvent("DocsNavigatorPressed", {
            destination: location.pathname,
            path: prevPath,
            navigatorText: e.target.innerText
          })
          studioDocslogEvent("PageEntered", {curr_path: location.pathname, curr_search: location.search, prev_path: prevPath})
          prevPath = location.pathname
        }
      });
    }, true);
  }

function footer() {
  onPathChangedListner();
}

footer();
